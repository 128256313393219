import getConfig from "next/config"
const { publicRuntimeConfig } = getConfig()

const Endpoint =
  publicRuntimeConfig && publicRuntimeConfig.API_SERVICE
    ? `http://${publicRuntimeConfig.API_SERVICE}:4000`
    : "http://localhost:3000"
const ApiPath =
  publicRuntimeConfig && publicRuntimeConfig.API_SERVICE ? "/api" : "/mock-api"

// const Endpoint = "http://localhost:4000"
// const ApiPath = "/api"
export { Endpoint, ApiPath }
